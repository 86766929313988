import React, { useState } from 'react';

import NavBar from "../components/navbar";
import Helmet from 'react-helmet'
import "../components/Layout/layout.css";

function Featured() {

    return (
        <>
            <Helmet>
                <body class='featured_page' />
            </Helmet>
            <NavBar></NavBar>
            <div className="featured_container">
            
            <div className="bio_container">

                    <img className="img_responsive"
                        alt="chevron"
                        src="/DA-Unit-photo-POMIAN-04MAY2021.jpg" />
                    <h3>TJ Pomian</h3>
                    <hr></hr>

                    <div className="bio">
                        <div className="bio_content">
                            <p>TJ Pomian works for Salesforce as the Area Vice President & General Manager leading the Enterprise Business Services vertical industry sales team for North America. He has been in the Software industry for over 25 years working in various Sales Leadership capacities in both Domestic & International markets. TJ has had the unique opportunity to have traveled to over 86 countries working with many types of companies & cultures throughout his career.</p>
                            <p>TJ is also actively serving as an Infantry Officer at the rank of Lieutenant Colonel in the United States Army Reserves. Over the past 20 years of service, he was called to active duty twice and deployed for two successful combat tours - one in Iraq where he led a platoon of Soldiers in Baghdad and a second deployment in Afghanistan where he led as an Infantry Captain in charge of a Company of Soldiers in Eastern Afghanistan. In his reserve capacity, TJ is currently serving as the Director of Force Management at the 80th Training Division for a two-star General where his Team is helping shape the force structure to meet the future strategic demands of the Army in 2028 & beyond.</p>
                            <p>TJ proudly graduated from The Citadel - the military college of Charleston, South Carolina and later earned his graduate MBA degree from Georgia Tech in Atlanta, Georgia. TJ resides in Alpharetta, Georgia with his wife Carrie and their 3 teenage children - twin daughters Chloe & Sophia and their son Thomas III.</p>
                        </div>
                    </div>
                </div>

                <div className="bio_container">

                    <img className="img_responsive"
                        alt="chevron"
                        src="/AWeeby.jpg" />
                    <h3>Ann Weeby</h3>
                    <hr></hr>

                    <div className="bio">
                        <div className="bio_content">
                        
                            <p>Ann Weeby is the Vice President of Workforce Innovation and the Head of Salesforce Miltary. She leads the company’s commitment to the military community focused on job training for veterans, reservists, and military spouses. In six years at Salesforce, she has helped 35,000+ members of the military community access free training and directly supported 1,200 people land jobs within the Salesforce ecosystem. </p>
                            <p>Ann knew she wanted to serve in the military from an early age and joined the Michigan Army National Guard Army while still in high school. After eight years of service, including one tour in Iraq, she dedicated her career to helping veterans find rewarding civilian careers. By working with nonprofits and the government she has also advocated for policies and practices that support post 9/11 military veterans and their families across the country. </p>
                            <p>Ann is the Board Chair for Technovation a global nonprofit that that inspires girls and families to be leaders and problem-solvers in their lives and their community and serves on the Workforce Investment Board for the City of San Francisco. She lives in Albany, California with her husband Jason and children Asa and Aleda. </p>
                        </div>
                    </div>
                </div>


                <div className="bio_container">

                    <img className="img_responsive"
                        alt="chevron"
                        src="/Amanda_headshot1.jpg" />
                    <h3>Amanda Balionis</h3>
                    <hr></hr>

                    <div className="bio">
                        <div className="bio_content">
                            <p>Amanda Balionis is a star broadcaster at CBS Sports, leading national coverage across both the PGA Tour and NFL. She joined the team in 2017, and has quickly become a fan favorite as the face of CBS’ primetime coverage including Masters and became the network’s inaugural social media correspondent for Super Bowl LIII. Amanda worked as a reporter for the PGA TOUR for five years before joining CBS. Based in Southern California, she is a certified yoga instructor and recently founded Puppies & Golf, a charitable foundation benefiting K9s for Warriors and dog shelters in need across the country.</p>
                        </div>
                    </div>
                </div>

                <div className="bio_container">

                    <img className="img_responsive"
                        alt="chevron"
                        src="/GW_headshot.jpg" />
                    <h3>Gary Woodland </h3>
                    <hr></hr>

                    <div className="bio">
                        <div className="bio_content">
                            <p>
                                Known for his prodigious length off the tee and widely recognized as one of the most athletic players on the PGA TOUR, 2019 US Open Winner Gary Woodland has cemented himself among golf’s elite competitors. Woodland’s multiple PGA TOUR wins demonstrate a consistent ability to compete on the biggest stage in professional golf.
</p>

                            <p>
                                Woodland had a dominant 2018-2019 season highlighted by his first major victory at the U.S. Open at Pebble Beach. With an incredible performance, he became the fourth champion in the tournament’s history to be double-digits under-par. The U.S. Open was Woodland’s 6th professional win and he capped his year off with his first ever team event, being chosen by Tiger Woods as a Captain’s Pick for the Presidents Cup team.
</p>

                            <p>
                                Over the last several years, Woodland has secured 14 top-10 finishes, including a win at the 2018 Waste Management Phoenix Open and runner-up finishes at both the 2019 Sentry Tournament of Champions and the 2018 CJ Cup at Nine Bridges. In addition to his win in Phoenix, Woodland has also captured wins at the 2013 Reno-Tahoe Open, the 2011 Valspar Championship, and teamed up with Matt Kuchar in the 2011 World Cup to become the first U.S. team to win the event since Tiger Woods and David Duval in 2000.
</p>

                            <p>
                                A native of Topeka, Kansas, Woodland graduated from Kansas University in 2007 and played one year of college basketball at Topeka’s Washburn University. Off the green, Woodland is as charming as he is humble and is dedicated to spending time with his family.</p>
                        </div>
                    </div>
                </div>
                <div className="bio_container">

                    <img className="img_responsive"
                        alt="chevron"
                        src="/folds.png" />
                    <h3>Folds Of Honor</h3>
                    <hr></hr>

                    <div className="bio">
                        <div className="bio_content">
                            <p>Since 2007, Folds of Honor has carried forth this singular, noble mission.To provide educational scholarships to spouses and children of America’s fallen and disabled service-members.</p>
                            <p className="center"><a href="https://www.fohevents.org/SalesforceGolfForChange/?controller=home" class="bio_link">Donate HERE</a></p>
                            <a class="bio_link" href="https://patriotgolfdays.com/locate-a-course/">Patriot Golf Day Registered Courses</a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Featured;